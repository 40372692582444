import React, { Component } from "react";
import { AppIcon, SocialIcons } from "./components/simple";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const corsesList = [
  {
    title: "Academic Advising",
    desc: "Assist in understanding the purposes and goals of higher education and its effects on your life and personal goals",
  },
  {
    title: "Admission Guidance",
    desc: "Offers a range of services to assist students in successfully applying to educational institutions.",
  },
  {
    title: "Career Counselling",
    desc: "Help individuals make informed decisions about their educational and career paths.",
  },
  {
    title: "Bank Loan Assistance",
    desc: "Helps students and their families for the process of securing loans to finance their education",
  },
];

const university = [
  [{ img: "1" }, { img: "2" }, { img: "3" }, { img: "4" }],
  [{ img: "5" }, { img: "6" }, { img: "7" }, { img: "8" }],
  [{ img: "9" }, { img: "10" }, { img: "11" }, { img: "12" }],
  [{ img: "13" }, { img: "14" }, { img: "15" }, { img: "16" }],
  [{ img: "17" }, { img: "18" }, { img: "19" }, { img: "20" }],
  [{ img: "21" }, { img: "22" }, { img: "23" }, { img: "24" }],
  [{ img: "25" }],
];
const universitymob = [
  [{ img: "1" }, { img: "2" }],
  [{ img: "3" }, { img: "4" }],
  [{ img: "5" }, { img: "6" }],
  [{ img: "7" }, { img: "8" }],
  [{ img: "9" }, { img: "10" }],
  [{ img: "11" }, { img: "12" }],
  [{ img: "13" }, { img: "14" }],
  [{ img: "15" }, { img: "16" }],
  [{ img: "17" }, { img: "18" }],
  [{ img: "19" }, { img: "20" }],
  [{ img: "21" }, { img: "22" }],
  [{ img: "23" }, { img: "24" }],
  [{ img: "25" }],
];

export default class App extends Component {
  constructor(props) {
    super(props);
    this.aboutRef = React.createRef(); // Create a ref for the "about" div
    this.footerRef = React.createRef(); // Create a ref for the "about" div
    this.formRef = React.createRef(); // Create a ref for the "about" div
    this.state = {
      isMenu: false,
    };
  }

  // Function to handle scrolling to the "about" div
  _scrollToAbout = () => {
    this.aboutRef.current.scrollIntoView({ behavior: "smooth" });
  };
  _scrollToFooter = () => {
    this.footerRef.current.scrollIntoView({ behavior: "smooth" });
  };
  _scrollToForm = () => {
    this.formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  _onSubmit = async (e) => {
    e.preventDefault();

    if (e.target.name.value === "") {
      alert("Enter your name");
      return;
    }
    if (e.target.phone.value === "") {
      alert("Enter your phone number");
      return;
    }

    alert(
      "Thank you for registering! One of our executives will be in touch with you shortly."
    );

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    const FormData = require("form-data");
    let data = new FormData();
    data.append("name", e.target.name.value);
    data.append("email", e.target.email.value);
    data.append("phone", e.target.phone.value);
    data.append("course", e.target.course.value);
    data.append("message", e.target.message.value);
    data.append("reference", params.get("ref") ?? "");

    document.getElementById("enquiryForm").reset();

    axios
      .post("https://intellectacademicsolutions.com/enquirey.php", data)
      .then((response) => console.log(response.data))
      .catch((error) => console.log(error));
  };

  componentDidMount() {
    const hash = window.location.hash.substring(1);

    if (hash === "registerForm")
      setTimeout(() => {
        this._scrollToForm();
      }, 500);
  }

  render() {
    const { isMenu } = this.state;
    return (
      <div className="a">
        {/* ////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////     HEADER     ////////////////////////// */}
        {/* ////////////////////////////////////////////////////////////////// */}
        <div className="aA">
          <div className="aAa">
            <AppIcon />
            <div className="aAaB">Home</div>
            <div className="aAaB" onClick={this._scrollToAbout}>
              About
            </div>
            <div className="aAaB" onClick={this._scrollToForm}>
              Courses
            </div>
            <div className="aAaB" onClick={this._scrollToFooter}>
              Contact
            </div>
          </div>
          <SocialIcons />
          <div
            className="aAb"
            onClick={() => this.setState({ isMenu: !isMenu })}
          >
            {isMenu ? (
              <div className="aAbA">
                <div className="aAbAa" onClick={this._scrollToAbout}>
                  About
                </div>
                <div className="aAbAa" onClick={this._scrollToForm}>
                  Courses
                </div>
                <div className="aAbAa" onClick={this._scrollToFooter}>
                  Contact
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {/* ////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////     HOME     ////////////////////////// */}
        {/* ////////////////////////////////////////////////////////////////// */}
        <div className="aB">
          <div className="aBa">
            Welcome to <span>Intellect Academic Solutions.</span> Your
            Educational Pathway Partner!
          </div>
          <div className="aBb">
            We offer complete assistance in finding top-rated universities and
            career-promising courses for your bright future.
          </div>
          <div className="aBcA">
            <div className="aBcAa" onClick={this._scrollToForm}>
              <div className="home" />
              Domestic
            </div>
            <div className="aBcAa" onClick={this._scrollToForm}>
              <div className="glob" />
              International
            </div>
          </div>
        </div>
        {/* ////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////     BRANDS     ////////////////////////// */}
        {/* ////////////////////////////////////////////////////////////////// */}

        <Carousel autoPlay infiniteLoop showStatus={false}>
          {(window.screen.width < 1000 ? universitymob : university).map(
            (it, k) => (
              <div className="aC" key={k}>
                {it.map((it1, k1) => (
                  <div className={"aCa un" + it1.img} key={k1 + k} />
                ))}
              </div>
            )
          )}
        </Carousel>

        {/* ////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////     ABOUT     ////////////////////////// */}
        {/* ////////////////////////////////////////////////////////////////// */}
        <div className="aD" ref={this.aboutRef}>
          <div className="aDa">
            <div className="aDaA">
              <div>About Us</div>
              Intellect Academic Solutions offers high-quality education across
              India and abroad, guiding students to reputable colleges and
              universities in Kerala, Karnataka, Tamil Nadu, Pondicherry, and
              Maharashtra. We ensure admissions to colleges with valid
              affiliations, such as AICTE, the Indian Medical Council, the
              Indian Nursing Council, state governments, and approved
              universities, providing undergraduate, postgraduate, diploma, and
              doctoral programs in various fields like engineering, medicine,
              nanomedical sciences, Ayurveda, management, and more.
            </div>
            <div className="aDaB">
              <div className="aDaBa" style={{ marginTop: 20 }}>
                <div className="aDaBaA">200+</div>
                <div className="aDaBaB">Courses</div>
                <div className="aDaBaC">
                  Partnership with top-rated universities
                </div>
              </div>
              <div className="aDaBa" style={{ marginBottom: 20 }}>
                <div className="aDaBaA">599+</div>
                <div className="aDaBaB">Students enrolled</div>
                <div className="aDaBaC">
                  Unparalleled satisfaction with our service{" "}
                </div>
              </div>
              <div className="aDaBa" style={{ marginTop: 20 }}>
                <div className="aDaBaA">99%</div>
                <div className="aDaBaB">Satisfaction rate</div>
                <div className="aDaBaC">
                  Provide personalized help and expert support{" "}
                </div>
              </div>
              <div className="aDaBa" style={{ marginBottom: 20 }}>
                <div className="aDaBaA">150+</div>
                <div className="aDaBaB">Courses instructors</div>
                <div className="aDaBaC">
                  To support Academic and Career Development{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////     COURSES     ////////////////////////// */}
        {/* ////////////////////////////////////////////////////////////////// */}
        <div className="aE">
          {/* <div className="aEa">Highly effective solutions</div> */}
          <div className="aEa">Our Services</div>
          <div className="aEb">
            We are committed to bring the best for those who aspire...
          </div>
          <div className="aEc">
            {corsesList.map((it, k) => (
              <div className="aEcA" key={k}>
                <div className="aEcAa">{it.title}</div>
                <div className="aEcAb">{it.desc}</div>
                <div className="aEcAc">0{k + 1}</div>
              </div>
            ))}
          </div>
        </div>
        {/* ////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////     FOROM     ////////////////////////// */}
        {/* ////////////////////////////////////////////////////////////////// */}
        <form
          className="aF"
          id="enquiryForm"
          onSubmit={this._onSubmit}
          ref={this.formRef}
        >
          <div className="aFa">Let us know what you think!</div>
          <div className="aFb">
            Our experienced advisors provide personalized guidance to help you
            explore career development.
          </div>
          <div className="aFc">
            <div className="aFcA">
              <div>Name *</div>
              <input id="name" placeholder="Brian Clark" />
            </div>
            <div className="aFcA">
              <div>Email *</div>
              <input id="email" placeholder="example@youremail.com" />
            </div>
            <div className="aFcA">
              <div>Phone *</div>
              <input id="phone" placeholder="(123) 456 - 7890" />
            </div>
            <div className="aFcA">
              <div>Course</div>
              <select id="course" placeholder="BRIX Agency">
                <option>Any course</option>
                <option>Medical</option>
                <option>Engineering</option>
                <option>Para-medical</option>
                <option>Arts & Science</option>
                <option>Allied Health Science</option>
                <option>Nursing</option>
                <option>Management course </option>
                <option>Architecture</option>
                <option>Law</option>
                <option>Hotel management</option>
                <option>Other</option>
              </select>
            </div>
            <div className="aFcB">
              <div>Stream / Message</div>
              <textarea
                id="message"
                placeholder="Type your message here..."
              ></textarea>
            </div>
            <button type="submit">Send message</button>
          </div>
        </form>
        {/* ////////////////////////////////////////////////////////////////// */}
        {/* ////////////////////////     FOOTER     ////////////////////////// */}
        {/* ////////////////////////////////////////////////////////////////// */}
        <div className="aG" ref={this.footerRef}>
          <div className="aG_A">
            <div className="aG_Aa">
              <AppIcon />
              <div className="aG_AaB">
                The most trusted Educational Pathway Partner.
              </div>
              <SocialIcons />
              <br />
            </div>
            <div className="aG_Ab">
              <div className="aG_AbA">Services</div>
              <div className="aG_AbB">Academic Advising</div>
              <div className="aG_AbB">Admission Counseling</div>
              <div className="aG_AbB">Career Counseling</div>
              <div className="aG_AbB">Workshops and Seminars</div>
            </div>
            <div className="aG_Ab">
              <div className="aG_AbA">Contact Us</div>
              <div className="aG_AbB">
                <div>
                  1st Cross Rd, near City Clinic, Zuzuvadi, Maruti Nagar
                  Bengaluru, Karnataka 560068
                </div>
              </div>
              <br />
              <div className="aG_AbB">
                <div>info@intellectacademicsolutions.com</div>
                <div onClick={() => window.open("tel:8147911901")}>
                  +91 81479 11901
                </div>
                <div onClick={() => window.open("tel:9567100352")}>
                  +91 95671 00352
                </div>
              </div>
            </div>
          </div>
          <div className="aG_B">
            Copyright © 2024 intellectacademicsolutions.com | All Rights
            Reserved |<span>&nbsp;Terms and Conditions | Privacy Policy</span>
          </div>
        </div>
      </div>
    );
  }
}
