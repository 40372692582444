import React from "react";

export function AppIcon() {
  return <div className="appIcon" />;
}

export function SocialIcons() {
  return (
    <div className="soicialIcon">
      <div
        className="soicialIcon_A fb"
        onClick={() =>
          window.open(
            "https://www.facebook.com/profile.php?id=61557088171634&sk=about",
            ""
          )
        }
      />
      {/* <div className="soicialIcon_A twitter" /> */}
      <div
        className="soicialIcon_A insta"
        onClick={() =>
          window.open(
            "https://www.instagram.com/intellect_academicsolutions?igsh=MXM1a3Roa2I3MGR3",
            ""
          )
        }
      />
      <div
        className="soicialIcon_A whatsapp"
        onClick={() => window.open("https://wa.me/918147911901", "")}
      />
      {/* <div className="soicialIcon_A linkedin" />
      <div className="soicialIcon_A youtube" /> */}
    </div>
  );
}
